import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./styles/Home.css";
import Navigation from "../../Utils/Navigation/Navigation";

import FQAs from "../../SVG/FQAs.js";
import Insights from "../../SVG/Insights.js";
import Legal from "../../SVG/Legal.js";
import Resources from "../../SVG/Resources.js";
import Support from "../../SVG/Support.js";

function Home() {
  const navigator = useNavigate();

  const [hoveredButton, setHoveredButton] = useState(null);
  const [SVGposition, setSVGposition] = useState({ x: "250", y: "250" });

  const [text, setText] = useState("");
  const fullText =
    "Boost your restaurant's efficiency and customer satisfaction with our mobile app. Easily manage orders, update menus, and engage customers with special offers—all from one platform.";
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let typeTimer;
    // Function to start the typewriter effect
    const typeWriter = (index) => {
      if (index < fullText.length) {
        setText((prev) => prev + fullText.charAt(index));
        typeTimer = setTimeout(() => typeWriter(index + 1), 100); // Typing speed
      }
    };

    // Set initial visibility after 3 seconds
    const timeoutId = setTimeout(() => {
      setVisible(true);
      typeWriter(0);
    }, 2000);

    // Interval to reset and repeat the animation
    const intervalId = setInterval(() => {
      // First, clear the typing timer to avoid mid-typing conflicts
      clearTimeout(typeTimer);

      // Start fading out
      setVisible(false); // Start fading out

      setTimeout(() => {
        // Clear text
        setText("");
        setVisible(true);
        typeWriter(0); // Start typing again after it becomes visible
      }, 5000); // Wait for fade out to complete before fading in and typing
    }, 30000); // Total animation cycle time

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(typeTimer);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1350) {
        setSVGposition({ x: "200", y: "200" });
      }
      if (window.innerWidth <= 1000) {
        setSVGposition({ x: "150", y: "150" });
      }
      if (window.innerWidth <= 750) {
        setSVGposition({ x: "100", y: "100" });
      }
      if (window.innerWidth <= 520) {
        setSVGposition({ x: "80", y: "80" });
      }
    }

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the window is already less than or equal to 100px
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="Home">
      <Navigation />
      <section className="heroSection">
        <div className="heroContent">
          <h1>
            Transform Your Restaurant’s Operations <br />
            with Cutting-Edge Technology
          </h1>
          <p className={`heroContentParagraph ${visible ? "visible" : ""}`}>
            {text}
          </p>
          <div className="ctaButtons">
            <Link to="/free-trial" className="ctaButtonPrimary">
              Start Free Trial
            </Link>
            <Link to="/schedule-demo" className="ctaButtonSecondary">
              See It in Action
            </Link>
          </div>
        </div>
      </section>
      <div className="homeFooter">
        <div className="homeFooter-button">
          <div
            className="CursorSVG"
            onClick={() => {
              navigator("/pricing#faqs_element");
            }}
            onMouseEnter={() => setHoveredButton("faqs")}
            onMouseLeave={() => setHoveredButton(null)}
          ></div>
          <FQAs
            color={hoveredButton === "faqs" ? "#ffffff" : "#000000"}
            width={SVGposition.x}
            height={SVGposition.y}
          />
          <span className="SVGtitlesDisc">FQAs</span>
        </div>

        <div className="homeFooter-button">
          {" "}
          <a
            href="https://reactnative.dev/docs/getting-started"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security measure for links opening in a new tab
            aria-label="Industry Insights - Read more about hospitality industry trends" // Accessible label for screen readers
            style={{ textDecoration: "none" }}
            className="CursorSVG"
            onMouseEnter={() => setHoveredButton("resources")}
            onMouseLeave={() => setHoveredButton(null)}
          ></a>
          <Resources
            color={hoveredButton === "resources" ? "#ffffff" : "#000000"}
            width={SVGposition.x}
            height={SVGposition.y}
          />
          <span className="SVGtitlesDisc">Resource Center</span>
        </div>

        <div className="homeFooter-button">
          <div
            className="CursorSVG"
            onClick={() => {
              navigator("/terms-and-conditions");
            }}
            onMouseEnter={() => setHoveredButton("legal")}
            onMouseLeave={() => setHoveredButton(null)}
          ></div>
          <Legal
            color={hoveredButton === "legal" ? "#ffffff" : "#000000"}
            width={SVGposition.x}
            height={SVGposition.y}
          />
          <span className="SVGtitlesDisc">Legal & Privacy</span>
        </div>

        <div className="homeFooter-button">
          <div
            className="CursorSVG"
            onClick={() => {
              navigator("/contact-us");
            }}
            onMouseEnter={() => setHoveredButton("support")}
            onMouseLeave={() => setHoveredButton(null)}
          ></div>
          <Support
            color={hoveredButton === "support" ? "#ffffff" : "#000000"}
            width={SVGposition.x}
            height={SVGposition.y}
          />
          <span className="SVGtitlesDisc">Support</span>
        </div>

        <div className="homeFooter-button">
          <a
            className="CursorSVG"
            href="https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/employmentandemployeetypes/articles/hospitalitybusinessesaremostlikelytobestrugglingtofillvacancies/2021-09-16/relateddata"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security measure for links opening in a new tab
            aria-label="Industry Insights - Read more about hospitality industry trends" // Accessible label for screen readers
            style={{ textDecoration: "none" }}
            onMouseEnter={() => setHoveredButton("insights")}
            onMouseLeave={() => setHoveredButton(null)}
          ></a>
          <Insights
            color={hoveredButton === "insights" ? "#ffffff" : "#000000"}
            width={SVGposition.x}
            height={SVGposition.y}
          />
          <span className="SVGtitlesDisc">Industry Insights</span>
        </div>
      </div>
    </div>
  );
}

export default Home;
