/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import "./styles/ScheduleDemo.css";
import Navigation from "../../Utils/Navigation/Navigation";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Default styling
import { useLocation } from "react-router-dom";

import Loading from "../../Loading";
import { imageList } from "./imageList";

function ScheduleDemo() {
  const [step, setStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessLocation: "",
    packageOption: "",
    date: "",
    company: "",
    specialFeature: "",
    preferredContactTime: "",
    additionalInformation: "",
    referralSource: "",
  });
  const location = useLocation();
  const [selectedTier, setSelectedTier] = useState({ select: false, tier: "" });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  useEffect(() => {
    if (location.hash === "#Basic-Tier") {
      setSelectedTier({ select: true, tier: "Basic Tier" });
    } else if (location.hash === "#Enterprise-Tier") {
      setSelectedTier({ select: true, tier: "Enterprise Tier" });
    } else if (location.hash === "#Premium-Tier") {
      setSelectedTier({ select: true, tier: "Premium Tier" });
    } else {
      setSelectedTier({ select: false, tier: "" });
    }
  }, [location]);

  useEffect(() => {
    setCurrentClass("transition-enter-active");
    const timeoutId = setTimeout(() => {
      setCurrentClass("");
    }, 300); // Corresponds to the CSS transition time
    return () => clearTimeout(timeoutId);
  }, [step]); // Triggered on step change

  const onDateChange = (newDate) => {
    setFormData({ ...formData, ["date"]: newDate });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nextStep = () => {
    setCurrentClass("transition-exit-active");
    setTimeout(() => setStep(step + 1), 300);
  };

  const previousStep = () => {
    setCurrentClass("transition-exit-active");
    setTimeout(() => setStep(step - 1), 300);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    nextStep();
  };

  const StepsToSchedule = () => {
    switch (step) {
      case 1:
        return (
          <form
            onSubmit={handleSubmit}
            className={`ScheduleDemoForm ${currentClass}`}
          >
            {selectedTier.select ? (
              <>
                <h2 className="SDFormTitle">
                  Discover the Benefits: {selectedTier.tier} Meeting
                </h2>
                <div className="SDFormParagraph">
                  Complete this form to schedule a meeting for{" "}
                  {selectedTier.tier}, and acquire the essential insights to
                  comprehend the advantages this tier offers your business.
                </div>
              </>
            ) : (
              <>
                <h2 className="SDFormTitle">Schedule a Demo</h2>
                <div className="SDFormParagraph">
                  Fill out this form for scheduling a demo, and gain all the
                  knowledge you need for understanding how beneficial the app
                  can be for your business
                </div>
              </>
            )}

            <div className="SDGroupOf2">
              <label className="SDrightSide">
                First name*
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="SDleftSide">
                Last name*
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="SDGroupOf2">
              <label className="SDrightSide">
                Business email address*
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="SDleftSide">
                Business phone number
                <input
                  type="tel"
                  name="phone"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="SDGroupOf2">
              <label className="SDrightSide">
                Location*
                <input
                  type="text"
                  name="businessLocation"
                  placeholder="Enter your business location"
                  value={formData.businessLocation}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="SDleftSide">
                Company*
                <input
                  type="text"
                  name="company"
                  placeholder="Enter business name"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <br />
            <br />
            <button type="submit" className="btn">
              Next
            </button>
          </form>
        );
      case 2:
        return (
          <form
            onSubmit={handleSubmit}
            className={`ScheduleDemoForm ${currentClass}`}
          >
            <div className="calendar-container">
              <h2>Choose a Date and Time</h2>
              <Calendar
                onChange={onDateChange}
                value={formData.date}
                className="react-calendar" // You can specify custom class names for styling
              />
              <br />
              <br />
              <label className="formLabel">
                What is your preferred contact time?*
                <select
                  name="preferredContactTime"
                  value={formData.preferredContactTime}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="Morning">Morning (9 am - 12 pm)</option>
                  <option value="Afternoon">Afternoon (12 pm - 5 pm)</option>
                  <option value="Evening">Evening (5 pm - 8 pm))</option>
                </select>
              </label>
              <br />
              <br />
              <div className="CalendarButtons">
                <button type="button" onClick={previousStep} className="btn">
                  Back
                </button>
                <button type="submit" className="btn">
                  Next
                </button>
              </div>
              <br />
              <br />
            </div>
          </form>
        );
      case 3:
        return (
          <form
            onSubmit={handleSubmit}
            className={`ScheduleDemoForm ${currentClass}`}
          >
            <h2>Select Your Package</h2>
            <label className="formLabel">
              Select Package Option*
              <select
                name="packageOption"
                value={formData.packageOption}
                onChange={handleChange}
                required
              >
                <option value="">Select Option</option>
                <option value="Basic-Tier">Basic Tier (£1,500 per year)</option>
                <option value="Premium-Tier">
                  Premium Tier (£3,000 per year)
                </option>
                <option value="Enterprise-Tier">
                  Enterprise Tier (£5,000 per year)
                </option>
              </select>
            </label>

            <label className="formLabel">
              Is it any special feature you want to add?
              <textarea
                type="text"
                name="specialFeature"
                placeholder="Enter any special feature you want to add"
                value={formData.specialFeature}
                onChange={handleChange}
              />
            </label>
            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Next
              </button>
            </div>
          </form>
        );
      case 4:
        return (
          <form
            className={`ScheduleDemoForm ${currentClass}`}
            method="POST"
            name="ScheduleDemo"
            data-netlify="true"
            onSubmit="submit"
          >
            {/* netlify-form -- START -- */}
            <input type="hidden" name="form-name" value="ScheduleDemo" />
            <div hidden>
              <label>First Name:</label>
              <input type="text" name="firstName" value={formData.firstName} />
            </div>
            <div hidden>
              <label>Last Name:</label>
              <input type="text" name="lastName" value={formData.lastName} />
            </div>
            <div hidden>
              <label>email:</label>
              <input type="text" name="email" value={formData.email} />
            </div>
            <div hidden>
              <label>phone:</label>
              <input type="text" name="phone" value={formData.phone} />
            </div>
            <div hidden>
              <label>businessLocation:</label>
              <input
                type="text"
                name="businessLocation"
                value={formData.businessLocation}
              />
            </div>
            <div hidden>
              <label>packageOption:</label>
              <input
                type="text"
                name="packageOption"
                value={formData.packageOption}
              />
            </div>
            <div hidden>
              <label>date:</label>
              <input type="text" name="date" value={formData.date} />
            </div>
            <div hidden>
              <label>company:</label>
              <input type="text" name="company" value={formData.company} />
            </div>
            <div hidden>
              <label>specialFeature:</label>
              <input
                type="text"
                name="specialFeature"
                value={formData.specialFeature}
              />
            </div>
            <div hidden>
              <label>preferredContactTime:</label>
              <input
                type="text"
                name="preferredContactTime"
                value={formData.preferredContactTime}
              />
            </div>

            {/* netlify-form -- END -- */}
            <h2>Additional Information</h2>
            <label>
              Specific Features of Interest:*
              <textarea
                placeholder="Please list the features you are most interested in exploring."
                name="additionalInformation"
                required
                onChange={handleChange}
              />
            </label>
            <br />
            <br />
            <label>
              How Did You Hear About Us?
              <input
                type="text"
                name="referralSource"
                onChange={handleChange}
              />
            </label>

            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        );
      default:
        return (
          <div className={`ScheduleDemoForm ${currentClass}`}>
            <h2>Thank you for scheduling a demo!</h2>
            <p>We will contact you soon with the details.</p>
          </div>
        );
    }
  };

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }

  return (
    <div className="ScheduleDemo">
      <div className="SD-lighting-effect"></div>
      <Navigation />
      <div className="StepsToSchedule">{StepsToSchedule()}</div>
      {/* <Footer /> */}
    </div>
  );
}
export default ScheduleDemo;
