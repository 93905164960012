/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import "./styles/FreeTrial.css";
import Navigation from "../../Utils/Navigation/Navigation";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Default styling

import Loading from "../../Loading";
import { imageList } from "./imageList";
function FreeTrial() {
  const [step, setStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessLocation: "",
    packageOption: "",
    date: "",
    company: "",
    specialFeature: "",
    preferredContactTime: "",
    additionalInformation: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  useEffect(() => {
    setCurrentClass("transition-enter-active");
    const timeoutId = setTimeout(() => {
      setCurrentClass("");
    }, 300); // Corresponds to the CSS transition time
    return () => clearTimeout(timeoutId);
  }, [step]); // Triggered on step change

  const onDateChange = (newDate) => {
    setFormData({ ...formData, ["date"]: newDate });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nextStep = () => {
    setCurrentClass("transition-exit-active");
    setTimeout(() => setStep(step + 1), 300);
  };

  const previousStep = () => {
    setCurrentClass("transition-exit-active");
    setTimeout(() => setStep(step - 1), 300);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    nextStep();
  };

  const StepsToSchedule = () => {
    switch (step) {
      case 1:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <h2 className="FTFormTitle">
              Start Free Trial <br />
              (30 days)
            </h2>
            <br />
            <div className="FTFormParagraph">
              In this section, we'll explain how the free trial works and what
              it includes. The 30 days - trial lets you explore almost all the
              features of our app, including the administration page and any
              additional options. You'll get a firsthand look at how the app can
              improve your restaurant's operations.
            </div>
            <div className="FTFormParagraph">
              By taking advantage of the free trial, you’ll gain a clear
              understanding of how our app can benefit your business. You’ll see
              how it simplifies management tasks, improves efficiency, and
              provides valuable insights. This opportunity allows you to
              determine if the app meets your needs before making a commitment.
            </div>

            <div className="CalendarButtons">
              <button type="submit" className="btn">
                Next
              </button>
            </div>
            <br />
          </form>
        );
      case 2:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <h2 className="FTFormTitle">How works</h2>
            <br />
            <div className="FTFormParagraph">
              We will provide you with an APK file for Android and an iOS
              installation file so you can install the app on both platforms.
              Additionally, you'll receive a URL where the Admin page will be
              hosted.
            </div>
            <div className="FTFormParagraph">
              Please note that the Free Trial version of the app will not be
              available on the App Store or Google Play Store, as it is not part
              of the standard app distribution. The Admin page will be hosted on
              a temporary URL, and the trial database will be limited in size
              and only available for the duration of the trial.
            </div>

            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Next
              </button>
            </div>
            <br />
          </form>
        );
      case 3:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <h2 className="FTFormTitle">Necessary Agreement</h2>
            <br />
            <div className="FTFormParagraph">
              Please be aware that during the trial period, we are not
              responsible for any data loss or data protection. The customer is
              solely responsible for maintaining the database. Additionally,
              online payment functionality is not available in the free trial,
              meaning the trial version will not support online payments like
              the full version.
            </div>
            <div className="FTFormParagraph">
              We are not liable for any issues that arise during the trial
              period, and customer support will prioritize paid customers over
              those using the free trial. To continue, please select "Agree" to
              acknowledge these terms.
            </div>

            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Agree
              </button>
            </div>
            <br />
          </form>
        );
      case 4:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <h2 className="FTFormTitle">Your Information</h2>
            <div className="FTFormParagraph">
              Fill out this form for scheduling a free trial, and gain all the
              knowledge you need for understanding how beneficial the app can be
              for your business.
            </div>
            <div className="FTGroupOf2">
              <label className="FTrightSide">
                First name*
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="FTleftSide">
                Last name*
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="FTGroupOf2">
              <label className="FTrightSide">
                Business email address*
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="FTleftSide">
                Business phone number
                <input
                  type="tel"
                  name="phone"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="FTGroupOf2">
              <label className="FTrightSide">
                Location*
                <input
                  type="text"
                  name="businessLocation"
                  placeholder="Enter your business location"
                  value={formData.businessLocation}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="FTleftSide">
                Company*
                <input
                  type="text"
                  name="company"
                  placeholder="Enter business name"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>

            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Next
              </button>
            </div>
          </form>
        );
      case 5:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <div className="calendar-container">
              <h2>Choose a Date and Time</h2>
              <Calendar
                onChange={onDateChange}
                value={formData.date}
                className="react-calendar" // You can specify custom class names for styling
              />
              <br />
              <br />
              <label className="formLabel">
                What is your preferred contact time?*
                <select
                  name="preferredContactTime"
                  value={formData.preferredContactTime}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="Morning">Morning (9 am - 12 pm)</option>
                  <option value="Afternoon">Afternoon (12 pm - 5 pm)</option>
                  <option value="Evening">Evening (5 pm - 8 pm))</option>
                </select>
              </label>
              <br />
              <br />
              <div className="CalendarButtons">
                <button type="button" onClick={previousStep} className="btn">
                  Back
                </button>
                <button type="submit" className="btn">
                  Next
                </button>
              </div>
              <br />
              <br />
            </div>
          </form>
        );
      case 6:
        return (
          <form
            onSubmit={handleSubmit}
            className={`FreeTrialForm ${currentClass}`}
          >
            <h2>Select Your Package</h2>
            <label className="formLabel">
              Select Package Option*
              <select
                name="packageOption"
                value={formData.packageOption}
                onChange={handleChange}
                required
              >
                <option value="">Select Option</option>
                <option value="Basic-Tier">Basic Tier (£1,500 per year)</option>
                <option value="Premium-Tier">
                  Premium Tier (£3,000 per year)
                </option>
                <option value="Enterprise-Tier">
                  Enterprise Tier (£5,000 per year)
                </option>
              </select>
            </label>

            <label className="formLabel">
              Is it any special feature you want to add?
              <textarea
                type="text"
                name="specialFeature"
                placeholder="Enter any special feature you want to add"
                value={formData.specialFeature}
                onChange={handleChange}
              />
            </label>
            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Next
              </button>
            </div>
          </form>
        );
      case 7:
        return (
          <form
            className={`FreeTrialForm ${currentClass}`}
            method="POST"
            name="FreeTrial"
            data-netlify="true"
            onSubmit="submit"
          >
            {/* netlify-form -- START -- */}
            <input type="hidden" name="form-name" value="FreeTrial" />
            <div hidden>
              <label>First Name:</label>
              <input type="text" name="firstName" value={formData.firstName} />
            </div>
            <div hidden>
              <label>Last Name:</label>
              <input type="text" name="lastName" value={formData.lastName} />
            </div>
            <div hidden>
              <label>email:</label>
              <input type="text" name="email" value={formData.email} />
            </div>
            <div hidden>
              <label>phone:</label>
              <input type="text" name="phone" value={formData.phone} />
            </div>
            <div hidden>
              <label>businessLocation:</label>
              <input
                type="text"
                name="businessLocation"
                value={formData.businessLocation}
              />
            </div>
            <div hidden>
              <label>packageOption:</label>
              <input
                type="text"
                name="packageOption"
                value={formData.packageOption}
              />
            </div>
            <div hidden>
              <label>date:</label>
              <input type="text" name="date" value={formData.date} />
            </div>
            <div hidden>
              <label>company:</label>
              <input type="text" name="company" value={formData.company} />
            </div>
            <div hidden>
              <label>specialFeature:</label>
              <input
                type="text"
                name="specialFeature"
                value={formData.specialFeature}
              />
            </div>
            <div hidden>
              <label>preferredContactTime:</label>
              <input
                type="text"
                name="preferredContactTime"
                value={formData.preferredContactTime}
              />
            </div>

            {/* netlify-form -- END -- */}
            <h2>Additional Information</h2>
            <label>
              Additional information or requests
              <textarea
                name="additionalInformation"
                placeholder="Enter any additional details or specific requests here."
                required
                onChange={handleChange}
              />
            </label>
            <br />
            <br />
            <div className="CalendarButtons">
              <button type="button" onClick={previousStep} className="btn">
                Back
              </button>
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        );
      default:
        return (
          <div className={`FreeTrialForm ${currentClass}`}>
            <h2>Thank you for scheduling a Free Trial!</h2>
            <p>We will contact you soon with the details.</p>
          </div>
        );
    }
  };

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }
  return (
    <div className="FreeTrial">
      <div className="FT-lighting-effect"></div>
      <Navigation />
      <div className="StepsToSchedule">{StepsToSchedule()}</div>
      {/* <Footer /> */}
    </div>
  );
}
export default FreeTrial;
